document.addEventListener('DOMContentLoaded', () => {
    //desktop slider 
    const slidersDesktop = document.querySelectorAll('.js-slider-reviews');

    if (slidersDesktop) {
        slidersDesktop.forEach((boxes) => {
            if (boxes) {
                new Swiper(boxes, {
                    slidesPerView: 1,
                    spaceBetween: 16,
                    slideToClickedSlide: true,
                    centeredSlides: true,
                    loop: true,
                    effect: 'slide',
                    speed: 1500,
                    pagination: false,
                    autoplay: {
                        delay: 3500
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-custom',
                        prevEl: '.swiper-button-prev-custom',
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 16,
                        },
                        992: {
                            slidesPerView: 2.35,
                            spaceBetween: 75,
                            centeredSlides: true,
                            loop: true
                        },
                    }
                });
            }
        });
    }

});